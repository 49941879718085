import { QuizList_allQuizzesJson_edges_node_node, QuizList_featuredImages_edges_node } from "../types/api"
import { Link } from "gatsby"
import * as React from "react"
import BackgroundImage from "gatsby-background-image"

type Quiz = QuizList_allQuizzesJson_edges_node_node
type Props = {
  node: Quiz
  index: number
  image: QuizList_featuredImages_edges_node
}

const arrow = require("../images/arrow_white_fg.png")

type State = {
  grow: boolean
}

export class QuizCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      grow: false,
    }
  }

  render() {
    let { node, image } = this.props
    return (
      <div key={node.id}>
        <a href={process.env.GATSBY_URL_PREFIX + `/${node.slug}/`}>
          <div className="grid-item people" data-category=".people">
            <div className="grid-card-inner grow" onMouseOver={this.grow} onMouseOut={this.shrink}>
              <BackgroundImage
                Tag="section"
                className="grid-card-image"
                fluid={image.childImageSharp.fluid}
                backgroundColor={`#040e18`}
              />
              <div className={`grid-category-wrapper inactive-cat ${this.state.grow ? "active-cat" : ""}`}>
                <div className="grid-category">People</div>
              </div>
              <div className={`grid-button-wrapper inactive-cat ${this.state.grow ? "active-cat" : ""}`}>
                <div className="grid-button">
                  <img src={arrow} alt="play arrow" className="play-arrow" />
                  <div className="grid-button-play">Play Now</div>
                </div>
              </div>
              <div className="grid-title-wrapper">
                <div className="grid-title" dangerouslySetInnerHTML={{ __html: node.title }} />
                {node.publishAt && (
                  <div className="grid-date">
                    Published:{" "}
                    {new Date(node.publishAt).toLocaleDateString("en-En", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }

  private grow = () => {
    this.setState({ grow: true })
  }
  private shrink = () => {
    this.setState({ grow: false })
  }
}
