import * as React from "react"
import { graphql } from "gatsby"
import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { QuizList, QuizList_allQuizzesJson_edges } from "../types/api"
import { QuizCard } from "../components/QuizCard"
import { shuffle } from "../model/helpers"
import * as TriviaStorage from "../model/TriviaStorage"
import { clearSnappy } from "../model/helpers"

const quizListLength = 20

type Props = {
  data: QuizList
  pageContext: {
    featuredImages: string[]
    slug?: string
  }
  location: any
}

type State = {
  quizes: QuizList_allQuizzesJson_edges[]
}

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      quizes: [],
    }
  }

  componentDidMount() {
    clearSnappy()
    this.getQuizzes()
  }

  render() {
    TriviaStorage.saveUtmFromLocation(this.props.location)
    let title = "Home"
    if (this.props.pageContext.slug) {
      title = this.props.pageContext.slug.charAt(0).toUpperCase() + this.props.pageContext.slug.slice(1)
    }

    return (
      <>
        <SEO
          title={title}
          description="Addictive Quizzes that People Love!"
          type="website"
          url={`${this.props.pageContext.slug}`}
        />
        <PrimaryHeader slug={this.props.pageContext.slug} />

        <div className="wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="grid">
                  {this.state.quizes.slice(0, quizListLength).map(({ node: { node } }, i) => {
                    const image = this.getImage(node.featuredImage)
                    return <QuizCard key={node.id} index={i} node={node} image={image} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer isQuizList={true} slug={this.props.pageContext.slug} />
      </>
    )
  }

  private quizesList = (onlyInclude: boolean = true) => {
    let quizes = this.props.data.allQuizzesJson.edges.filter(e => {
      // Show only quizzes that have published date.
      if (!e.node.node.publishAt) {
        return false
      }
      // Show only quizzes that are published in last 7 days.
      const publishAt = new Date(e.node.node.publishAt)
      publishAt.setHours(0,0,0,0)
      const today = new Date()
      today.setHours(0,0,0,0)
      const diff = today.getTime() - publishAt.getTime()
      const diffDays = diff / (1000 * 3600 * 24)
      if (diffDays >= 7 || diffDays < 0) {
        return false
      }
      if (!e.node.node.featuredImage) {
        return false
      }
      if (!this.props.pageContext.slug) {
        // homepage
        if (onlyInclude) {
          return e.node.node.includeAtHomepage
        }
        return !e.node.node.includeAtHomepage
      } else {
        // tagpage
        if (onlyInclude && e.node.node.includeAtTagpage) {
          return e.node.node.tags.edges.find(t => t.node.slug === this.props.pageContext.slug)
        }
        if (!onlyInclude && !e.node.node.includeAtTagpage) {
          return e.node.node.tags.edges.find(t => t.node.slug === this.props.pageContext.slug)
        }
      }
    })
    quizes = shuffle([...quizes])

    return quizes
  }

  private getImage = (path: string) => {
    let edge = this.props.data.featuredImages.edges.filter(e => e.node.relativePath === path)[0]
    if (edge) {
      return edge.node
    }
    return null
  }

  private getQuizzes() {
    let quizes = this.quizesList()
    if (quizes.length < quizListLength) {
      let randomQuizes = this.quizesList(false)
      quizes = quizes.concat(randomQuizes.slice(0, quizListLength - quizes.length))
    }
    this.setState({ quizes: quizes })
  }
}

export const query = graphql`
  query QuizList($featuredImages: [String]) {
    featuredImages: allFile(filter: { relativePath: { in: $featuredImages } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxHeight: 300) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    allQuizzesJson {
      edges {
        node {
          node {
            id
            featuredImage
            slug
            title
            includeAtTagpage
            includeAtHomepage
            publishAt
            tags {
              edges {
                node {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`
